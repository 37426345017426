import React, { useRef } from "react";
import { Button, Form, Input } from "antd";
import { postCancelOpportunity } from "../../../store/opportunities";
import { useDispatch } from "react-redux";

const { TextArea } = Input;
const CancelOpportunity = ({ cancelOpportunityHandler, singleOpportunity }) => {
    const formRef = useRef();
    const dispatch = useDispatch();

    const onFinish = (values) => {
        const formData = { status: "cancelled", reject_note: values.reject_note, opportunity_id: singleOpportunity?.id }
        dispatch(postCancelOpportunity(formData)).then((res => {
            if (!res?.error) {
                cancelOpportunityHandler()
            } else {
                let obj = res?.payload?.error?.errors;
                let customValidation = [];
                for (let key in obj) {
                    customValidation.push({
                        name: key,
                        errors: obj[key],
                    });
                }
                formRef.current?.setFields(customValidation);
            }
        }))
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <div>
            <h1>Cancel Opportunity</h1>
            <Form
                name="cancel_opportunity"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
                ref={formRef}
            >
                <Form.Item
                    label="Reason"
                    name="reject_note"
                    rules={[
                        {
                            required: true,
                            message: "Please enter your reason!",
                        },
                    ]}
                >
                    <TextArea rows={4} placeholder="Reason" />
                </Form.Item>

                <Form.Item>
                    <Button className="bg-miracle text-white hover:bg-miracle" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default CancelOpportunity;
