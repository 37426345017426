import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../services/instance";
import { getSingleInvestor } from "./investors";
import { message } from "antd";

const initialState = {
  updateBorrowerStatusLoading: false,
  deactivateBorrowerLoading: false,
  activateBorrowerLoading: false,
  userProfile: null,
  singleBorrowerLoading: false,
  // borrower profile
  companyInfoLoading: false,
  contactPersonLoading: false,
  companyDocsLoading: false,
  revenueDataLoading: false,
  assignAdvisorLoading: false,
  updateExpireDateLoading: false,
  // charge wallet
  chargeWalletLoading: false,
};

//^ ========= getSingleWithdrawReq ============
export const getSingleBorrower = createAsyncThunk(
  "borrowers/getSingleBorrower",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .get(`/admin/get_single_borrower/${id}`)
        .then((response) => {
          console.log(response?.data?.data, "getSingleBorrower ================", id);
          return response?.data?.data;
        })
        .catch((error) => {
          console.log(error);
          // return rejectWithValue({
          //   message: error.message,
          //   error: error.response.data,
          // });
        });
    } catch (e) {
      throw e;
    }
  }
);
//^ ========= approve/reject borrower ============
export const postUpdateBorrowerStatus = createAsyncThunk(
  "borrowers/postUpdateBorrowerStatus",
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .post(`/admin/update_request`, data)
        .then((response) => {
          message.success(response?.data?.message);
          console.log(response, "update");
          dispatch(getSingleBorrower(id));
          return response;
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
        });
    } catch (e) {
      throw e;
    }
  }
);
//^ ========= deactivate borrower ============
export const postDeactivateBorrower = createAsyncThunk(
  "borrowers/postDeactivateBorrower",
  async ({ body, user_type }, { rejectWithValue, dispatch }) => {
    console.log(body, user_type, "helllllllllllllo")
    try {
      return await axiosInstance
        .post(`/admin/deactivate-user`, body)
        .then((response) => {
          message.success(response?.data?.message);
          console.log(response, "deactivate");
          if (user_type == 3) {
            dispatch(getSingleBorrower(body?.user_id));
          } else {
            dispatch(getSingleInvestor(body?.user_id));
          }
          return response;
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
        });
    } catch (e) {
      throw e;
    }
  }
);
export const postActivateBorrower = createAsyncThunk(
  "borrowers/postActivateBorrower",
  async ({ body, user_type }, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .post(`/admin/activate-user`, body)
        .then((response) => {
          message.success(response?.data?.message);
          console.log(response, "activate");
          if (user_type == 3) {
            dispatch(getSingleBorrower(body?.user_id));
          } else {
            dispatch(getSingleInvestor(body?.user_id));
          }
          return response;
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
        });
    } catch (e) {
      throw e;
    }
  }
);
//^ ========= company info  ============
export const postCompanyInfo = createAsyncThunk(
  "borrowers/postCompanyInfo",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .post(`/admin/store-kyc-step-1-company-info`, data)
        .then((response) => {
          message.success(response?.data?.message);
          dispatch(getSingleBorrower(data?.user_id));
          console.log(response, "update");
          return response;
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
        });
    } catch (e) {
      throw e;
    }
  }
);
//^ ========= contact person info  ============
export const postContactPersonInfo = createAsyncThunk(
  "borrowers/postContactPersonInfo",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .post(`/admin/store-kyc-step-1-contact-info`, data)
        .then((response) => {
          message.success(response?.data?.message);
          dispatch(getSingleBorrower(data?.user_id));
          console.log(response, "update");
          return response;
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
        });
    } catch (e) {
      throw e;
    }
  }
);
//^ ========= company docs info  ============
export const postCompanyDocs = createAsyncThunk(
  "borrowers/postCompanyDocs",
  async ({ formData, id }, { rejectWithValue, dispatch }) => {
    try {
      axiosInstance.defaults.headers.post["Content-Type"] = "multipart/form-data";
      return await axiosInstance
        .post(`admin/store-kyc-step-1-company-docs`, formData)
        .then((response) => {
          message.success(response?.data?.message);
          dispatch(getSingleBorrower(id));
          console.log(response, "update");
          return response;
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
        });
    } catch (e) {
      throw e;
    }
  }
);
//^ ========= revenue data info  ============
export const postRevenueData = createAsyncThunk(
  "borrowers/postRevenueData",
  async ({ formData, id }, { rejectWithValue, dispatch }) => {
    try {
      axiosInstance.defaults.headers.post["Content-Type"] = "multipart/form-data";
      return await axiosInstance
        .post(`/admin/store-kyc-step-2-connect-revenue`, formData)
        .then((response) => {
          message.success(response?.data?.message);
          dispatch(getSingleBorrower(id));
          console.log(response, "update");
          return response;
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= assign advisor  ============
export const postAssignAdvisor = createAsyncThunk(
  "borrowers/postAssignAdvisor",
  async ({ body, user_type }, { rejectWithValue, dispatch }) => {

    try {
      return await axiosInstance
        .post(`/admin/assign-advisor`, body)
        .then((response) => {
          message.success(response?.data?.message);
          if (user_type === 2) {
            dispatch(getSingleInvestor(body?.user_id));
          } else if (user_type === 3) {
            dispatch(getSingleBorrower(body?.user_id));
          }
          console.log(response, "update");
          return response;
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= UPDATE expire date DOC   ============
export const postExpireDate = createAsyncThunk(
  "borrowers/postExpireDate",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .post(`/admin/update-users-documents`, data)
        .then((response) => {
          console.log(response, "update---------------------------- store");
          message.success(response?.data?.message);
          return response;
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= charge wallet  ============
export const postCreateWallet = createAsyncThunk(
  "borrowers/postCreateWallet",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .post(`/admin/chargeWallet`, data)
        .then((response) => {
          message.success(response?.data?.message);
          console.log(response, "update");
          return response;
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= get lean transactions csv  ============ //
export const getLeanTransactionsCSV = createAsyncThunk(
  "borrowers/getLeanTransactionsCSV",
  async (accountId, { rejectWithValue }) => {
    try {
      return await axiosInstance
        .get(`admin/lean/get-account-transactions-csv?account_id=${accountId}`)
        .then((response) => {
          console.log(response?.data?.data?.fileUrl, "opportunity csv");
          const a = document.createElement("a");
          a.href = response?.data?.data?.fileUrl;
          a.download = "lean_transactions.csv";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          return response?.data?.data?.fileUrl;
        })
        .catch((error) => {
          console.log(error);
          return rejectWithValue({
            message: error.message,
            error: error.response.data,
          });
        });
    } catch (e) {
      throw e;
    }
  }
);
/*-----------------------Slice----------------*/

const borrowerSlice = createSlice({
  name: "borrowerSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // single borrower
    builder.addCase(getSingleBorrower.pending, (state) => {
      state.singleBorrowerLoading = true;
    });
    builder.addCase(getSingleBorrower.fulfilled, (state, action) => {
      state.singleBorrowerLoading = false;
      state.userProfile = action.payload;
    });
    builder.addCase(getSingleBorrower.rejected, (state) => {
      state.singleBorrowerLoading = false;
    });

    // update borrower status
    builder.addCase(postUpdateBorrowerStatus.pending, (state) => {
      state.updateBorrowerStatusLoading = true;
    });
    builder.addCase(postUpdateBorrowerStatus.fulfilled, (state, action) => {
      state.updateBorrowerStatusLoading = false;
    });
    builder.addCase(postUpdateBorrowerStatus.rejected, (state) => {
      state.updateBorrowerStatusLoading = false;
    });

    // deactivate account
    builder.addCase(postDeactivateBorrower.pending, (state) => {
      state.deactivateBorrowerLoading = true;
    });
    builder.addCase(postDeactivateBorrower.fulfilled, (state, action) => {
      state.deactivateBorrowerLoading = false;
    });
    builder.addCase(postDeactivateBorrower.rejected, (state) => {
      state.deactivateBorrowerLoading = false;
    });

    // activate account
    builder.addCase(postActivateBorrower.pending, (state) => {
      state.activateBorrowerLoading = true;
    });
    builder.addCase(postActivateBorrower.fulfilled, (state, action) => {
      state.activateBorrowerLoading = false;
    });
    builder.addCase(postActivateBorrower.rejected, (state) => {
      state.activateBorrowerLoading = false;
    });

    // company info
    builder.addCase(postCompanyInfo.pending, (state) => {
      state.companyInfoLoading = true;
    });
    builder.addCase(postCompanyInfo.fulfilled, (state, action) => {
      state.companyInfoLoading = false;
    });
    builder.addCase(postCompanyInfo.rejected, (state) => {
      state.companyInfoLoading = false;
    });
    // contact person info
    builder.addCase(postContactPersonInfo.pending, (state) => {
      state.contactPersonLoading = true;
    });
    builder.addCase(postContactPersonInfo.fulfilled, (state, action) => {
      state.contactPersonLoading = false;
    });
    builder.addCase(postContactPersonInfo.rejected, (state) => {
      state.contactPersonLoading = false;
    });

    // company Docs
    builder.addCase(postCompanyDocs.pending, (state) => {
      state.companyDocsLoading = true;
    });
    builder.addCase(postCompanyDocs.fulfilled, (state, action) => {
      state.companyDocsLoading = false;
    });
    builder.addCase(postCompanyDocs.rejected, (state) => {
      state.companyDocsLoading = false;
    });

    // revenue Data
    builder.addCase(postRevenueData.pending, (state) => {
      state.revenueDataLoading = true;
    });
    builder.addCase(postRevenueData.fulfilled, (state, action) => {
      state.revenueDataLoading = false;
    });
    builder.addCase(postRevenueData.rejected, (state) => {
      state.revenueDataLoading = false;
    });

    // assign advisor
    builder.addCase(postAssignAdvisor.pending, (state) => {
      state.assignAdvisorLoading = true;
    });
    builder.addCase(postAssignAdvisor.fulfilled, (state, action) => {
      state.assignAdvisorLoading = false;
    });
    builder.addCase(postAssignAdvisor.rejected, (state) => {
      state.assignAdvisorLoading = false;
    });

    // update borrower expiration dates of docs
    builder.addCase(postExpireDate.pending, (state) => {
      state.updateExpireDateLoading = true;
    });
    builder.addCase(postExpireDate.fulfilled, (state, action) => {
      state.updateExpireDateLoading = false;
    });
    builder.addCase(postExpireDate.rejected, (state) => {
      state.updateExpireDateLoading = false;
    });

    // charge wallet
    builder.addCase(postCreateWallet.pending, (state) => {
      state.chargeWalletLoading = true;
    });
    builder.addCase(postCreateWallet.fulfilled, (state, action) => {
      state.chargeWalletLoading = false;
    });
    builder.addCase(postCreateWallet.rejected, (state) => {
      state.chargeWalletLoading = false;
    });
  },
});

export default borrowerSlice.reducer;
