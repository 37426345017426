import React, { useEffect } from "react";
import { Spin } from "antd";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { RequireAuth } from "./RequireAuth";
import Detail from "../Container/Opportunities/OpportunityDeatils";
import NotificationPage from "../Container/NotificationsPage";
import VipRequest from "../Container/Investors/Vip/_id";
const Investors = React.lazy(() => import("../Container/Investors"));
const InvestorsProfile = React.lazy(() => import("../Container/Investors/Profile"));
const InvestorsUpdateProfile = React.lazy(() => import("../Container/Investors/Profile/update"));

const Loan = React.lazy(() => import("../Container/Loan"));
const LoanDetail = React.lazy(() => import("../Container/Loan/details/index"));
const DueRepayments = React.lazy(() => import("../Container/DueRepayments"));
const SingleDueRepayment = React.lazy(() => import("../Container/DueRepayments/_id"));
const CloseOpportunity = React.lazy(() => import("../Container/Opportunities/CloseOpportunity"));
const Home = React.lazy(() => import("../Container/Opportunities"));
const Login = React.lazy(() => import("../Container/login"));
const Borrowers = React.lazy(() => import("../Container/borrowers"));
const BorrowerProfile = React.lazy(() => import("../Container/borrowers/Profile"));
const BorrowerUpdateProfile = React.lazy(() => import("../Container/borrowers/Profile/Update"));

const FundsManagement = React.lazy(() => import("../Container/FundsManagement"));
const WithdrawalRequest = React.lazy(() => import("../Container/WithdrawalRequest"));
const WithDrawalRequestDetails = React.lazy(() => import("../Container/WithdrawalRequest/WithdrawalDetails"));
const LoanManagement = React.lazy(() => import("../Container/LoanManagement"));
const LoanManagementDetails = React.lazy(() =>
  import("../Container/LoanManagement/RequestedBorrowers/RequestedBorrowerDetail")
);
const FinancialAdvisors = React.lazy(() => import("../Container/FinancialAdvisors"));
const WalletLedger = React.lazy(() => import("../Container/WalletLedger"));
const FAQs = React.lazy(() => import("../Container/FAQs"));
const Transactions = React.lazy(() => import("../Container/Transactions"));
const AdminTransactions = React.lazy(() => import("../Container/AdminTransactions"));
const AdminProfile = React.lazy(() => import("../Container/AdminProfile"));

/* ----------------- opportunities --------------- */
const CreateOpportunity = React.lazy(() => import("../Container/Opportunities/CreateOpportunity"));

const MyRoutes = () => {
  const isSuperAdmin = localStorage.getItem("user_type") == 0;
  useEffect(() => {
    //  console.log('knlk', Armaghan)
  }, []);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <React.Suspense>
            <RequireAuth>
              <Home />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="/opportunity/add"
        element={
          <React.Suspense>
            <RequireAuth>
              <CreateOpportunity />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="/opportunity/edit/:id"
        element={
          <React.Suspense>
            <RequireAuth>
              <CreateOpportunity />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="/opportunity/close-opportunity/:id"
        element={
          <React.Suspense>
            <RequireAuth>
              <CloseOpportunity />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="/loan"
        element={
          <React.Suspense>
            <RequireAuth>
              <Loan />
            </RequireAuth>
          </React.Suspense>
        }
      />

      <Route
        path="/loan/:id"
        element={
          <React.Suspense>
            <RequireAuth>
              <LoanDetail />
            </RequireAuth>
          </React.Suspense>
        }
      />

      <Route
        path="/due-repayments"
        element={
          <React.Suspense>
            <RequireAuth>
              <DueRepayments />
            </RequireAuth>
          </React.Suspense>
        }
      />

      <Route
        path="/due-repayments/:id"
        element={
          <React.Suspense>
            <RequireAuth>
              <SingleDueRepayment />
            </RequireAuth>
          </React.Suspense>
        }
      />

      <Route
        path="/notifications"
        element={
          <React.Suspense>
            <RequireAuth>
              <NotificationPage />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="/opportunity/:id"
        element={
          <React.Suspense>
            <RequireAuth>
              <Detail />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="/financial-advisors"
        element={
          <React.Suspense>
            <RequireAuth>
              <FinancialAdvisors />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="/transactions"
        element={
          <React.Suspense>
            <RequireAuth>
              <Transactions />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="/funds-management"
        element={
          <React.Suspense>
            <RequireAuth>
              <FundsManagement />
            </RequireAuth>
          </React.Suspense>
        }
      />
      {/* <Route
        path="/withdrawal-request"
        element={
          <React.Suspense>
            <RequireAuth>
              <WithdrawalRequest />
            </RequireAuth>
          </React.Suspense>
        }
      /> */}
      {/* <Route
        path="/withdrawal-request/:id"
        element={
          <React.Suspense>
            <RequireAuth>
              <WithDrawalRequestDetails />
            </RequireAuth>
          </React.Suspense>
        }
      /> */}
      <Route
        path="/loan-management"
        element={
          <React.Suspense>
            <RequireAuth>
              <LoanManagement />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="/loan-management/:id"
        element={
          <React.Suspense>
            <RequireAuth>
              <LoanManagementDetails />
            </RequireAuth>
          </React.Suspense>
        }
      />

      <Route
        path="/login"
        element={
          <React.Suspense>
            <Login />
          </React.Suspense>
        }
      />
      <Route
        path="/borrower/update/:id"
        element={
          <React.Suspense>
            <RequireAuth>
              <BorrowerUpdateProfile />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="/investors"
        element={
          <React.Suspense>
            <RequireAuth>
              <Investors />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="/investors/:id"
        element={
          <React.Suspense>
            <RequireAuth>
              <InvestorsProfile />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="/investors/update/:id"
        element={
          <React.Suspense>
            <RequireAuth>
              <InvestorsUpdateProfile />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="/vip-requests/:id"
        element={
          <React.Suspense>
            <RequireAuth>
              <VipRequest />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="/borrowers"
        element={
          <React.Suspense>
            <RequireAuth>
              <Borrowers />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="/borrowers/:id"
        element={
          <React.Suspense>
            <RequireAuth>
              <BorrowerProfile />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="/wallet-ledger/:id"
        element={
          <React.Suspense>
            <RequireAuth>
              <WalletLedger />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="/FAQs"
        element={
          <React.Suspense>
            <RequireAuth>
              <FAQs />
            </RequireAuth>
          </React.Suspense>
        }
      />

      <Route
        path="/admin-transactions"
        element={
          <React.Suspense>
            <RequireAuth>
              <AdminTransactions />
            </RequireAuth>
          </React.Suspense>
        }
      />

      <Route
        path="/admin-profile"
        element={
          <React.Suspense>
            <RequireAuth>
              <AdminProfile />
            </RequireAuth>
          </React.Suspense>
        }
      />
    </Routes>
  );
};

export default MyRoutes;
