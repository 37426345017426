import { useState } from "react";
import { Row, Col, Modal } from "antd";
import { ReactComponent as PDF } from "../../../assets/svgs/PDF.svg";
import { ReactComponent as View } from "../../../assets/svgs/View.svg";
import { ReactComponent as Download } from "../../../assets/svgs/Download.svg";

const DocumentViewer = ({ handleOk, handleCancel, isModalOpen, link, fileName, type }) => {
  console.log(link, "link")
  console.log(process.env.REACT_APP_baseURL, "process.env.REACT_APP_baseURLprocess.env.REACT_APP_baseURL")
  return (
    <>
      {/* 
    https://devbk.dnaneer.com/storage/dnaneer/68/upgrade/t3GLg51ynwou8J9b7pToN67OVgZSjJOntqElQOoU.pdf
    
    
    */}
      {/* <p>
        {process.env.REACT_APP_baseURL}
      </p>
      <p>
        {link}
      </p>
      <p>

        {link?.includes(process.env.REACT_APP_baseURL) ? link : `${process.env.REACT_APP_baseURL}${link}`}
      </p> */}
      <Modal centered open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false} width={"900px"}>
        <h1>File Preview</h1>
        <iframe
          src={link?.includes(process.env.REACT_APP_baseURL) ? link : `${process.env.REACT_APP_baseURL}${link}`}
          title="PDF Viewer"
          style={{ width: "100%", height: "800px" }}
        />
        <h4>{fileName}</h4>
      </Modal>
      { }
      <div className="document-view-container">
        <div style={{ display: "flex", alignItems: "center" }}>
          <PDF width="16px" height="16px" />

          <div className="flex flex-col ml-2">
            <span className="text-xs">{type === "primary_low" ? "The Article of Association" : type?.replace(/_/g, " ")}</span>
            <span className="text-sm">{fileName}</span>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div onClick={() => handleOk()}>
            <View />
          </div>
          {/* 
          if link starts contains use link process.env.REACT_APP_baseURL
          else use link
          */}
          <a
            href={link?.includes(process.env.REACT_APP_baseURL) ? link : `${process.env.REACT_APP_baseURL}${link}`}
            download={fileName}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Download style={{ marginLeft: "10px" }} />
          </a>
        </div>
      </div>
    </>
  );
};


const SupportedDocuments = ({ document, page, className }) => {
  console.log(document, "documentdocumentdocument");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className={className}>
      <Row gutter={[16, 2]}>
        <Col md={8}>
          {typeof document === "object" ? (
            <DocumentViewer
              isModalOpen={isModalOpen}
              handleOk={handleOk}
              handleCancel={handleCancel}
              link={document?.link}
              fileName={
                document?.original_name
                  ? document?.original_name?.length > 10
                    ? `${document?.original_name.substring(0, 10)}...`
                    : document?.original_name
                  : document?.type?.length > 10
                    ? `${document?.type.substring(0, 30)}...`
                    : document?.type
              }
              type={page === "opportunity_details" ? `attachment-${Number(document?.type) + 1}` : document?.type}
            />
          ) : (
            <DocumentViewer
              isModalOpen={isModalOpen}
              handleOk={handleOk}
              handleCancel={handleCancel}
              fileName={"Supporting-Document.pdf"}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SupportedDocuments;

