import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../services/instance";
import { message } from "antd";

const initialState = {
    // add description transaction
    updateDescriptionLoading: false,
    // admin transactions
    adminTransactions: null,
    adminTransactionsLoading: false,

    // exportCsv
    exportCSVLoading: false,

};

// Helper function for error handling
const handleAsyncError = (error, rejectWithValue) => {
    console.error(error);
    message.error(error?.response?.data?.message || "Request failed");
    return rejectWithValue({
        message: error.message,
        error: error?.response?.data,
    });
};

//^ ========================================= admin transactions ============================================
export const getAdminTransactions = createAsyncThunk(
    "adminTransactions/getAdminTransactions",
    async (params, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/admin/transactions`, { params });
            return response?.data?.data;
        } catch (error) {
            return handleAsyncError(error, rejectWithValue);
        }
    }
);

//^ ========================================= admin transactions ============================================

export const updateTransactionDescription = createAsyncThunk(
    "adminProfile/postOwnerPhoneValidate",
    async ({ id, data }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosInstance.post(`/admin/transactions/${id}/update`, data);
            if (response) {
                message.success(response?.data?.message);
                return response?.data?.data;
            }
        } catch (error) {
            return handleAsyncError(error, rejectWithValue);
        }
    }
);


//^ ========= admin transactions csv  ============ //
export const getAdminTransactionsCSV = createAsyncThunk(
    "adminTransactions/getAdminTransactionsCSV",
    async (_, { rejectWithValue }) => {
        try {
            return await axiosInstance
                .get(`/admin/export-transactions-with-real`)
                .then((response) => {
                    const a = document.createElement("a");
                    a.href = response?.data?.data?.fileUrl;
                    a.download = "lean_transactions.csv";
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    return response?.data?.data?.fileUrl;
                })
                .catch((error) => {
                    console.log(error);
                    return rejectWithValue({
                        message: error.message,
                        error: error.response.data,
                    });
                });
        } catch (e) {
            throw e;
        }
    }
);

const adminTransactionsSlice = createSlice({
    name: "adminTransactions",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            //^ ========================================= add transactions ================================
            .addCase(updateTransactionDescription.pending, (state) => {
                state.updateDescriptionLoading = true;
            })
            .addCase(updateTransactionDescription.fulfilled, (state, action) => {
                state.updateDescriptionLoading = false;
                state.adminTransactions = action.payload;
            })
            .addCase(updateTransactionDescription.rejected, (state) => {
                state.updateDescriptionLoading = false;
            })
            //^ ========================================= get admin transactions ================================
            .addCase(getAdminTransactions.pending, (state) => {
                state.adminTransactionsLoading = true;
            })
            .addCase(getAdminTransactions.fulfilled, (state, action) => {
                state.adminTransactionsLoading = false;
                state.adminTransactions = action.payload;
            })
            .addCase(getAdminTransactions.rejected, (state) => {
                state.adminTransactionsLoading = false;
                state.adminBankAccounts = null;
            })
            //^ ==================================== get admin transactions csv ==========================================
            .addCase(getAdminTransactionsCSV.pending, (state) => {
                state.exportCSVLoading = true;
            })
            .addCase(getAdminTransactionsCSV.fulfilled, (state, action) => {
                state.exportCSVLoading = false;
            })
            .addCase(getAdminTransactionsCSV.rejected, (state) => {
                state.adminBankAccountsLoading = false;
                state.exportCSVLoading = false;
            })

    },
});

export default adminTransactionsSlice.reducer;
