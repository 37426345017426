// ! ----------------------------- imports start ---------------------------------- //
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
//antd
import { Row, Col, Tabs, Button, Spin, Modal, Popconfirm, Form, Input, Dropdown, Image, message, Tag } from "antd";
import { MoreOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { ReactComponent as Check } from "../../../assets/svgs/Check.svg";
// css

import _ from "lodash";
import Layout from "../../../sharedModules/defaultLayout";
import { getSingleVipRequest, updateVipRequest } from "../../../services/ApiHandler";

// components
import ImageUpload from "../../../Components/ImageUpload/ImageUpload";
import SupportedDocuments from "../RequestedInvestors/SuppportedDocuments";
import RejectCase from "../../../Components/forms/investor/RejectCase";
// dayjs
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);


// ! ----------------------------- imports end ---------------------------------- //
const VipRequest = () => {
   const { id } = useParams();
   const navigate = useNavigate();
   const [request, setRequest] = useState(null)
   const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
   const [selectedRequest, setSelectedRequest] = useState(null);

   // ^ ----------------------------- constants start ---------------------------------- //
   console.log(id)
   // ^ ----------------------------- constants end ---------------------------------- //

   // & ----------------------------- methods start ---------------------------------- //


   const getSingleRequestData = async () => {
      try {
         const { data } = await getSingleVipRequest(id);
         if (data) {
            console.log("update Status", data);
            setRequest(data.data)

            // after success call getloans with status active again again
         }
      } catch (error) {
         console.log("err", error?.response?.data?.message);

      } finally {
      }
   };
   const getFormattedUserProfile = () => {
      return {
         ...request
      };
   };
   const VipRequestStatus = async ({ value, reason }) => {
      try {
         const { data } = await updateVipRequest({
            user_id: request?.user_id || selectedRequest?.user_id,
            reject_note: reason || null,
            status: value,
         });
         if (data) {
            console.log("update Status", data);
            message.success(data?.message ?? "status updated successfully");
            setIsRejectModalOpen(false);
            getSingleRequestData()
            // after success call getloans with status active again again
         }
      } catch (error) {
         console.log("err", error?.response?.data?.message);
         message.error(error?.response?.data?.message);
      } finally {
      }
   };
   const onChangeVipRequestStatus = async (value) => {
      if (value === "rejected") {
         setIsRejectModalOpen(true);
      } else {
         VipRequestStatus({ value: value });
      }
   };
   useEffect(() => {
      getSingleRequestData()
   }, [])


   // & ----------------------------- methods end ---------------------------------- //



   // * ---------------------------- template start --------------------------------- //
   return (
      <Layout>

         {/* This logic is written for fast work it will change by time */}
         <div className="mt-8 borrower-profile" >
            <Spin spinning={false}>
               {request && (
                  <>
                     <div className="profile-container">
                        <div className="flex justify-end mb-3">
                           <Button
                              onClick={() => navigate(`/investors?key=vip_req`)}
                              className="text-sm font-medium bg-primary border border-primary text-white"
                           >
                              <ArrowLeftOutlined />
                              Back
                           </Button>
                        </div>
                        <Row className="flex items-center">
                           <Col lg={22}>
                              <div className="user-profile">
                                 <div className="borrower-user-image">
                                    <Image src="/assets/images/Mask%20Group.png"
                                       preview={false}
                                    />
                                 </div>
                                 <div className=" ml-3">
                                    <p className="capitalize">{request?.user?.institutional?.investor_name || "Investor"}</p>
                                    <p>{request?.user?.institutional?.position}</p>
                                    <div className="additional-information">
                                       <div className="info">
                                          <h2>investor name</h2>
                                          <p>
                                             {/* {request?.user?.institutional?.can_invest == false && "Didn't complete KYC"}
                                             {request?.user?.institutional?.can_invest == true && "Activated"} */}
                                             <span> {request?.investor_name || "Investor"}</span>
                                          </p>
                                       </div>
                                       <div className="line" />

                                       <div className="info">
                                          <h2>Status</h2>
                                          <p>
                                             {/* {request?.user?.institutional?.can_invest == false && "Didn't complete KYC"}
                                             {request?.user?.institutional?.can_invest == true && "Activated"} */}
                                             <Tag className={`tag-status tag-status__${request?.status}`}> {request?.status}</Tag>
                                          </p>
                                       </div>
                                       <div className="line" />
                                       {/* <div className="info">
                                          <h2>Investor Status</h2>
                                          <p>
                                             {(request?.user?.investor_kyc_step_1 == false || request?.user?.investor_kyc_step_2 == false) && "Didn't complete KYC"}
                                             {(request?.user?.investor_kyc_step_1 && request?.user?.investor_kyc_step_2) && "Activated"}

                                          </p>
                                       </div>
                                       <div className="line" /> 
                                       */}
                                       <div className="info">
                                          <h2>Email</h2>
                                          <p>{request?.user?.email}</p>
                                       </div>
                                       <div className="line" />
                                       <div className="info">
                                          <h2>Creation date</h2>
                                          <p>{(request?.created_at && (dayjs(request?.created_at).format("YYYY-MM-DD"))) || "-"}</p>
                                       </div>
                                       <div className="line" />
                                       <Tag className="cursor-pointer" bordered={false} color="processing" onClick={() => navigate(`/investors/${request?.user?.id}`)}>
                                          view investor profile
                                       </Tag>
                                    </div>
                                 </div>
                              </div>

                           </Col>
                           <Col lg={2} >
                              <Dropdown
                                 menu={{
                                    items: [
                                       {
                                          label: (
                                             <span
                                                onClick={() =>
                                                   onChangeVipRequestStatus("approved")
                                                }
                                             >
                                                Approve
                                             </span>
                                          ),
                                          key: "approved",
                                       },
                                       {
                                          label: (
                                             <span
                                                onClick={() =>
                                                   onChangeVipRequestStatus("rejected")
                                                }
                                             >
                                                Reject
                                             </span>
                                          ),
                                          key: "rejected",
                                       },
                                    ],
                                 }}
                                 trigger={["click"]}>
                                 <div className="ml-auto flex justify-center item-center w-8 h-8 rounded-md bg-brilliantWhite">
                                    <MoreOutlined style={{ color: "#2B48F4" }} />
                                 </div>
                              </Dropdown>
                           </Col>
                        </Row>
                        <div className="docs-holder border-t border-slate-200 py-4">
                           <h3 className="text-base font-semibold mb-4 ">Vip Data </h3>
                           <div className="info-panel-container">
                              {request?.criteria.map((item) => {
                                 return <div className="flex items-center gap-1">
                                    <Check className="w-5 h-5" />
                                    <p className="mb-0">{item.name}</p>
                                 </div>
                              })}
                           </div>
                           <h3 className="text-base font-semibold mt-7 ">Uploaded Docs </h3>
                           <div className="flex flex-wrap gap-2 mt-4">
                              {request.documents.map((doc, index) => (
                                 <SupportedDocuments
                                    key={index}
                                    className="small-doc-view"
                                    document={doc}
                                 />
                              ))}
                           </div>
                        </div>

                     </div>
                     <RejectCase
                        label={`Please Add Your Reason For Rejection`}
                        visible={isRejectModalOpen}
                        handleCancel={() => {
                           setIsRejectModalOpen(false);
                        }}
                        handleOk={(reason) => VipRequestStatus({ request: selectedRequest, value: 'rejected', reason })}
                     />
                  </>
               )}
            </Spin>

         </div>
      </Layout>
   );
   // * ---------------------------- template end --------------------------------- //
};

export default VipRequest;
