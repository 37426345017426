import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../services/instance";
import { message } from "antd";

const initialState = {
    // due repayments
    dueRepaymentsList: null,
    dueRepaymentsLoading: false,

    // single due repayment
    singleDueRepayment: null,
    singleDueRepaymentLoading: false,

    // pay due repayment
    payDueRepaymentLoading: false,

};

//^ ========= get all due repayments ============//
export const getAllDueRepayments = createAsyncThunk(
    "loans/getAllDueRepayments",
    async (params, { rejectWithValue }) => {
        try {
            return await axiosInstance
                .get(`/admin/due-repayments`, { params })
                .then((response) => {
                    return response?.data?.data;
                })
                .catch((error) => {
                    console.log(error);
                    // return rejectWithValue({
                    //   message: error.message,
                    //   error: error.response.data,
                    // });
                });
        } catch (e) {
            throw e;
        }
    }
);
//^ ========= get single repayment ============//
export const getSingleDueRepayment = createAsyncThunk(
    "loans/getSingleDueRepayment",
    async (id, { rejectWithValue }) => {
        try {
            return await axiosInstance
                .get(`/admin/due-repayments/${id}`)
                .then((response) => {
                    return response?.data?.data;
                })
                .catch((error) => {
                    console.log(error);
                    // return rejectWithValue({
                    //   message: error.message,
                    //   error: error.response.data,
                    // });
                });
        } catch (e) {
            throw e;
        }
    }
);
//^ ========= post pay repayment ============//
export const postPayRepayment = createAsyncThunk(
    "loans/postPayRepayment",
    async (data, { rejectWithValue, dispatch }) => {
        console.log(data, "-----------")
        try {
            return await axiosInstance
                .post(`/admin/pay-due-repayment`, data)
                .then((response) => {
                    message.success(response?.data?.message);
                    dispatch(getSingleDueRepayment(data?.repayment_id))
                    console.log(response?.data?.data, "update");
                    return response?.data?.data
                })
                .catch((error) => {
                    console.log(error);
                    message.error(error?.response?.data?.message);
                });
        } catch (e) {
            throw e;
        }
    }
);
/*-----------------------Slice----------------*/

const loansSlice = createSlice({
    name: "loansSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        /* ----------------------------------- due repayments --------------------------- */
        // get all due repayments
        builder.addCase(getAllDueRepayments.pending, (state) => {
            state.dueRepaymentsLoading = true;
        });
        builder.addCase(getAllDueRepayments.fulfilled, (state, action) => {
            state.dueRepaymentsLoading = false;
            state.dueRepaymentsList = action.payload;
        });
        builder.addCase(getAllDueRepayments.rejected, (state) => {
            state.dueRepaymentsLoading = false;
        });

        //  get single due repayments
        builder.addCase(getSingleDueRepayment.pending, (state) => {
            state.singleDueRepaymentLoading = true;
        });
        builder.addCase(getSingleDueRepayment.fulfilled, (state, action) => {
            state.singleDueRepaymentLoading = false;
            state.singleDueRepayment = action.payload;
        });
        builder.addCase(getSingleDueRepayment.rejected, (state) => {
            state.singleDueRepaymentLoading = false;
        });

        // pay repayment
        builder.addCase(postPayRepayment.pending, (state) => {
            state.payDueRepaymentLoading = true;
        });
        builder.addCase(postPayRepayment.fulfilled, (state, action) => {
            state.payDueRepaymentLoading = false;
            //state.singleDueRepayment = action.payload;
        });
        builder.addCase(postPayRepayment.rejected, (state) => {
            state.payDueRepaymentLoading = false;
        });
    },
});

export default loansSlice.reducer;
