import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../services/instance";
import { message } from "antd";
import { handleAsyncError } from "../utils/Helper";

const initialState = {
    // admin users
    usersCSV: null,
    usersCSVLoading: false,
    allAdmins: null,
    allAdminLoader: false,
    addAdminLoader: false,
    editAdminLoader: false,
    deleteAdminLoader: false,

    // bank accounts
    adminBankAccounts: null,
    adminBankAccountsLoading: false,

    addBankLoadingFirstStep: false,
    addBankLoadingSecondStep: false,
    deleteBankLoading: false,

    // admin settings
    adminSettings: null,
    adminSettingsLoading: false,

    phoneValidatePhoneLoading: false,
    updateAdminSettingsLoading: false,
};


//^ ========= get admins ============
export const getAllAdmins = createAsyncThunk(
    "adminProfile/getAllAdmins",
    async (_, { rejectWithValue, dispatch }) => {
        try {
            return await axiosInstance
                .get(`/admin/getadmins`)
                .then((response) => {
                    return response?.data
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (e) {
            throw e;
        }
    }
);
//^ ========= add admin ============
export const postAddAdmin = createAsyncThunk(
    "adminProfile/postAddAdmin",
    async (data, { rejectWithValue, dispatch }) => {
        try {
            return await axiosInstance
                .post(`admin/create_sub_admin`, data)
                .then((response) => {
                    message.success(response?.data?.message);
                    console.log(response, "update");
                    dispatch(getAllAdmins());
                })
                .catch((error) => {
                    console.log(error);
                    message.error(error?.response?.data?.message);
                    return rejectWithValue({
                        message: error.message,
                        error: error.response.data,
                    });
                });
        } catch (e) {
            throw e;
        }
    }
);

//^ ========= edit admin ============
export const postUpdateAdmin = createAsyncThunk(
    "adminProfile/postUpdateAdmin",
    async ({ id, data }, { rejectWithValue, dispatch }) => {
        try {
            return await axiosInstance
                // admin/update_sub_admin/{admin}
                .post(`admin/update_sub_admin/${id}`, data)
                .then((response) => {
                    message.success(response?.data?.message);
                    console.log(response, "update");
                    dispatch(getAllAdmins());
                })
                .catch((error) => {
                    console.log(error);
                    message.error(error?.response?.data?.message);
                    return rejectWithValue({
                        message: error.message,
                        error: error.response.data,
                    });
                });
        } catch (e) {
            throw e;
        }
    }
);

//^ ========= delete admin ============
export const deleteAdmin = createAsyncThunk("adminProfile/deleteAdmin", async (id, { rejectWithValue, dispatch }) => {
    try {
        return await axiosInstance
            .delete(`admin/delete_sub_admin/${id}`)
            .then((response) => {
                message.success(response?.data?.message);
                console.log(response, "delete");
                dispatch(getAllAdmins());
            })
            .catch((error) => {
                console.log(error);
                message.error(error?.response?.data?.message || "request failed");
            });
    } catch (e) {
        throw e;
    }
});

//^ ========= get investors csv  ============ //
export const getUsersCSV = createAsyncThunk("adminProfile/getUsersCSV", async (data, { rejectWithValue }) => {
    try {
        return await axiosInstance
            .get(`/admin/getadmins/export_csv`)
            .then((response) => {
                console.log(response?.data?.data?.fileUrl, "getUsersCSV");
                return response?.data?.data?.fileUrl;
            })
            .catch((error) => {
                console.log(error);
                return rejectWithValue({
                    error: error.message,
                });
            });
    } catch (e) {
        throw e;
    }
});

//^ ========================================= bank accounts ============================================
// Fetch admin bank accounts
export const getAdminBankAccounts = createAsyncThunk(
    "adminProfile/getAdminBankAccounts",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/bank-accounts`);
            return response?.data?.data;
        } catch (error) {
            return handleAsyncError(error, rejectWithValue);
        }
    }
);

// Add admin bank account - 1st step
export const postAddBankAccountFirstStep = createAsyncThunk(
    "adminProfile/postAddBankAccountFirstStep",
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosInstance.post(`/admin/iban-validation`, data);
            if (response) {
                message.success(response?.data?.message);
                return response?.data;
            }
        } catch (error) {
            return handleAsyncError(error, rejectWithValue);
        }
    }
);

// Add admin bank account - 2nd step
export const postAddBankAccountSecondStep = createAsyncThunk(
    "adminProfile/postAddBankAccountSecondStep",
    async (data, { rejectWithValue, dispatch }) => {
        try {
            axiosInstance.defaults.headers.post["Content-Type"] = "multipart/form-data";
            const response = await axiosInstance.post(`/bank-accounts`, data);
            if (response) {
                message.success(response?.data?.message);
                return response?.data?.data;
            }
        } catch (error) {
            return handleAsyncError(error, rejectWithValue);
        }
    }
);

// Delete admin bank account
export const deleteBankAccount = createAsyncThunk(
    "adminProfile/deleteBankAccount",
    async (id, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosInstance.delete(`/bank-accounts/${id}`);
            if (response) {
                dispatch(getAdminBankAccounts())
                message.success(response?.data?.message);
                return response?.data;
            }
        } catch (error) {
            return handleAsyncError(error, rejectWithValue);
        }
    }
);

//^ ========================================= admin settings ============================================
// Fetch admin settings
export const getAdminSettings = createAsyncThunk(
    "adminProfile/getAdminSettings",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/admin/get-settings`);
            return response?.data?.data;
        } catch (error) {
            return handleAsyncError(error, rejectWithValue);
        }
    }
);

// admin phone number validate 
export const postOwnerPhoneValidate = createAsyncThunk(
    "adminProfile/postOwnerPhoneValidate",
    async (data, { rejectWithValue, dispatch }) => {
        const { form_data, id } = data;
        try {
            axiosInstance.defaults.headers.post["Content-Type"] = "multipart/form-data";
            const response = await axiosInstance.post(`/admin/phone-number-valdate/${id}`, form_data);
            if (response) {
                message.success(response?.data?.message);
                return response?.data;
            }
        } catch (error) {
            return handleAsyncError(error, rejectWithValue);
        }
    }
);



// Update admin settings - 2nd step
export const postUpdateAdminSettings = createAsyncThunk(
    "adminProfile/postUpdateAdminSettings",
    async (data, { rejectWithValue, dispatch }) => {
        const { form_data, id } = data;
        try {
            const response = await axiosInstance.post(`/admin/settings/update/${id}`, form_data);
            if (response) {
                message.success(response?.data?.message);
                return response?.data?.data;
            }
        } catch (error) {
            return handleAsyncError(error, rejectWithValue);
        }
    }
);




const adminProfileSlice = createSlice({
    name: "adminProfileSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //^ ========================================= admin users ============================================

        builder
            // all admin
            .addCase(getAllAdmins.pending, (state) => {
                state.allAdminLoader = true;
            })
            .addCase(getAllAdmins.fulfilled, (state, action) => {
                state.allAdminLoader = false;
                state.allAdmins = action.payload;
            })
            .addCase(getAllAdmins.rejected, (state) => {
                state.allAdminLoader = false;
            })

            // add admins
            .addCase(postAddAdmin.pending, (state) => {
                state.addAdminLoader = true;
            })
            .addCase(postAddAdmin.fulfilled, (state, action) => {
                state.addAdminLoader = false;
            })
            .addCase(postAddAdmin.rejected, (state) => {
                state.addAdminLoader = false;
            })

            // edit admin
            .addCase(postUpdateAdmin.pending, (state) => {
                state.editAdminLoader = true;
            })
            .addCase(postUpdateAdmin.fulfilled, (state, action) => {
                state.editAdminLoader = false;
            })

            .addCase(postUpdateAdmin.rejected, (state) => {
                state.editAdminLoader = false;
            })

            // delete admin
            .addCase(deleteAdmin.pending, (state) => {
                state.deleteAdminLoader = true;
            })
            .addCase(deleteAdmin.fulfilled, (state, action) => {
                state.deleteAdminLoader = false;
            })

            .addCase(deleteAdmin.rejected, (state) => {
                state.deleteAdminLoader = false;
            })

            // admin users csv
            .addCase(getUsersCSV.pending, (state) => {
                state.usersCSVLoading = true;
            })
            .addCase(getUsersCSV.fulfilled, (state, action) => {
                state.usersCSVLoading = false;
                state.usersCSV = action.payload;
            })
            .addCase(getUsersCSV.rejected, (state) => {
                state.usersCSVLoading = false;
            })
            //^ ========================================= bank accounts ============================================
            // get bank accounts
            .addCase(getAdminBankAccounts.pending, (state) => {
                state.adminBankAccountsLoading = true;
            })
            .addCase(getAdminBankAccounts.fulfilled, (state, action) => {
                state.adminBankAccountsLoading = false;
                state.adminBankAccounts = action.payload;
            })
            .addCase(getAdminBankAccounts.rejected, (state) => {
                state.adminBankAccountsLoading = false;
                state.adminBankAccounts = null;
            })

            // add bank account 1st step
            .addCase(postAddBankAccountFirstStep.pending, (state) => {
                state.addBankLoadingFirstStep = true;
            })
            .addCase(postAddBankAccountFirstStep.fulfilled, (state) => {
                state.addBankLoadingFirstStep = false;
            })
            .addCase(postAddBankAccountFirstStep.rejected, (state) => {
                state.addBankLoadingFirstStep = false;
            })

            // add bank account 2nd step
            .addCase(postAddBankAccountSecondStep.pending, (state) => {
                state.addBankLoadingSecondStep = true;
            })
            .addCase(postAddBankAccountSecondStep.fulfilled, (state, action) => {
                state.addBankLoadingSecondStep = false;
                state.adminBankAccounts = action.payload;
            })
            .addCase(postAddBankAccountSecondStep.rejected, (state) => {
                state.addBankLoadingSecondStep = false;
            })

            // delete bank account
            .addCase(deleteBankAccount.pending, (state) => {
                state.deleteBankLoading = true;
            })
            .addCase(deleteBankAccount.fulfilled, (state) => {
                state.deleteBankLoading = false;
            })
            .addCase(deleteBankAccount.rejected, (state) => {
                state.deleteBankLoading = false;
            })
            //^ ========================================= admin settings ============================================
            // get admin settings
            .addCase(getAdminSettings.pending, (state) => {
                state.adminSettingsLoading = true;
            })
            .addCase(getAdminSettings.fulfilled, (state, action) => {
                state.adminSettingsLoading = false;
                state.adminSettings = action.payload;
            })
            .addCase(getAdminSettings.rejected, (state) => {
                state.adminSettingsLoading = false;
            })

            // phone Validate 
            .addCase(postOwnerPhoneValidate.pending, (state) => {
                state.phoneValidatePhoneLoading = true;
            })
            .addCase(postOwnerPhoneValidate.fulfilled, (state) => {
                state.phoneValidatePhoneLoading = false;
            })
            .addCase(postOwnerPhoneValidate.rejected, (state) => {
                state.phoneValidatePhoneLoading = false;
            })

            // update admin settings 
            .addCase(postUpdateAdminSettings.pending, (state) => {
                state.updateAdminSettingsLoading = true;
            })
            .addCase(postUpdateAdminSettings.fulfilled, (state, action) => {
                state.updateAdminSettingsLoading = false;
                state.adminSettings = action.payload;
            })
            .addCase(postUpdateAdminSettings.rejected, (state) => {
                state.updateAdminSettingsLoading = false;
            })
    },
});

export default adminProfileSlice.reducer;
