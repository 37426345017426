import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../services/instance";
import { message } from "antd";

const initialState = {
  allOpportunities: null,
  allOpportunitiesLoading: false,

  createOpportunityFirstStepLoading: false,
  createOpportunitySecondStepLoading: false,

  updateOpportunityFirstStepLoading: false,
  updateOpportunitySecondStepLoading: false,

  cancelOpportunityLoading: false,

  singleOpportunityLoading: false,
  singleOpportunity: null,
  borrowerBankListLoading: false,
  borrowerBankList: [],
  opportunitiesListCSV: null,
  opportunitiesListCSVLoading: false,
  opportunityDropData: null,
  opportunityDropDataLoading: false,
};

//^ ========= get all opportunities ============
export const getAllOpportunities = createAsyncThunk(
  "opportunities/getAllOpportunities",
  async (params, { rejectWithValue }) => {
    try {
      return await axiosInstance
        .get(`admin/oppurtunity_list`, { params })
        .then((response) => {
          return response?.data?.data[0];
        })
        .catch((error) => {
          return rejectWithValue({
            message: error.message,
            error: error.response.data,
          });
        });
    } catch (e) {
      throw e;
    }
  }
);
//^ ========= create opportunity first step ============
export const postCreateOpportunityFirstStep = createAsyncThunk(
  "opportunities/postCreateOpportunity",
  async (data, { rejectWithValue, dispatch }) => {
    axiosInstance.defaults.headers.post["Content-Type"] = "multipart/form-data";
    try {
      return await axiosInstance
        .post(`/admin/create_opportunity`, data)
        .then((response) => {
          message.success(response?.data?.message);
          console.log(response?.data?.data, "update");
          console.log(response, "create opportunity response");
          return response?.data?.data;
        })
        .catch((error) => {
          message.error(error?.response?.data?.message);
          return rejectWithValue({
            message: error.message,
            error: error.response.data,
          });
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= create opportunity second step ============
export const postCreateOpportunitySecondStep = createAsyncThunk(
  "opportunities/postCreateOpportunitySecondStep",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .post(`/admin/repayments`, data)
        .then((response) => {
          message.success(response?.data?.message);
          console.log(response?.data?.data, "update");
          console.log(response, "update opportunity response");
          return response?.data?.data;
        })
        .catch((error) => {
          message.error(error?.response?.data?.message);
          return rejectWithValue({
            message: error.message,
            error: error.response.data,
          });
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= update opportunity first step ============
export const postUpdateOpportunityFirstStep = createAsyncThunk(
  "opportunities/postUpdateOpportunityFirstStep",
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    axiosInstance.defaults.headers.post["Content-Type"] = "multipart/form-data";
    try {
      return await axiosInstance
        .post(`/admin/update_opportunity/${id}`, data)
        .then((response) => {
          message.success(response?.data?.message);
          console.log(response?.data?.data, "update");
          console.log(response, "create opportunity response");
          return response?.data?.data[0];
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
          return rejectWithValue({
            message: error.message,
            error: error.response.data,
          });
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= update opportunity second step ============
export const postUpdateOpportunitySecondStep = createAsyncThunk(
  "opportunities/postUpdateOpportunitySecondStep",
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .put(`/admin/repayments/${id}`, data)
        .then((response) => {
          message.success(response?.data?.message);
          console.log(response?.data?.data, "update");
          console.log(response, "create opportunity response");
          return response?.data?.data[0];
        })
        .catch((error) => {
          message.error(error?.response?.data?.message);
          return rejectWithValue({
            message: error.message,
            error: error.response.data,
          });
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= cancel opportunity ============
export const postCancelOpportunity = createAsyncThunk(
  "opportunities/postCancelOpportunity",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .post("/admin/opportunity_approve", data)
        .then((response) => {
          message.success(response?.data?.status || response?.data?.message);
          console.log(response?.data?.data, "cancel");
          console.log(response, "create opportunity response");
          return response?.data?.data[0];
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
          return rejectWithValue({
            message: error.message,
            error: error.response.data,
          });
        });
    } catch (e) {
      throw e;
    }
  }
);
//^ ========= view single opportunity ============ //
export const getSingleOpportunity = createAsyncThunk(
  "opportunities/getSingleOpportunity",
  async (id, { rejectWithValue }) => {
    try {
      return await axiosInstance
        .get(`/admin/get_single_opportunity/${id}`)
        .then((response) => {
          console.log(response?.data?.data[0], "single opportunity");
          return response?.data?.data[0];
        })
        .catch((error) => {
          return rejectWithValue({
            message: error.message,
            error: error.response.data,
          });
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= get borrower bank list ============ //
export const getBorrowerBankList = createAsyncThunk(
  "opportunities/getBorrowerBankList",
  async ({ id }, { rejectWithValue }) => {
    try {
      return await axiosInstance
        .get(`/admin/borrowerPersonalBankAccount/${id}`)
        .then((response) => {
          console.log(response?.data?.data, "bank list");
          return response?.data?.data;
        })
        .catch((error) => {
          return rejectWithValue({
            message: error.message,
            error: error.response.data,
          });
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= get opportunities list csv  ============ //
export const getOpportunitiesCSV = createAsyncThunk(
  "opportunities/getOpportunitiesCSV",
  async (data, { rejectWithValue }) => {
    try {
      return await axiosInstance
        .get(`/admin/oppurtunity_list/export_csv`)
        .then((response) => {
          console.log(response?.data?.data?.fileUrl, "opportunity csv");
          const a = document.createElement("a");
          a.href = response?.data?.data?.fileUrl;
          a.download = "opportunities.csv";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          return response?.data?.data?.fileUrl;
        })
        .catch((error) => {
          console.log(error);
          return rejectWithValue({
            message: error.message,
            error: error.response.data,
          });
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= get dropdown data  ============ //
export const getOpportunityDropData = createAsyncThunk(
  "opportunities/getOpportunityDropData",
  async (_, { rejectWithValue }) => {
    try {
      return await axiosInstance
        .get(`/admin/opportunity_dropdown_data`)
        .then((response) => {
          return response?.data?.data[0];
        })
        .catch((error) => {
          return rejectWithValue({
            message: error.message,
            error: error.response.data,
          });
        });
    } catch (e) {
      throw e;
    }
  }
);

/*-----------------------Slice----------------*/

const opportunitiesSlice = createSlice({
  name: "opportunitiesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //   get all opportunities
    builder.addCase(getAllOpportunities.pending, (state) => {
      state.allOpportunitiesLoading = true;
    });
    builder.addCase(getAllOpportunities.fulfilled, (state, action) => {
      state.allOpportunitiesLoading = false;
      state.allOpportunities = action.payload;
    });
    builder.addCase(getAllOpportunities.rejected, (state) => {
      state.allOpportunitiesLoading = false;
    });

    //  create opportunity (first step)
    builder.addCase(postCreateOpportunityFirstStep.pending, (state) => {
      state.createOpportunityFirstStepLoading = true;
    });
    builder.addCase(postCreateOpportunityFirstStep.fulfilled, (state, action) => {
      state.createOpportunityFirstStepLoading = false;
      state.singleOpportunity = action.payload;
    });
    builder.addCase(postCreateOpportunityFirstStep.rejected, (state) => {
      state.createOpportunityFirstStepLoading = false;
    });

    //  create opportunity (second step)
    builder.addCase(postCreateOpportunitySecondStep.pending, (state) => {
      state.createOpportunitySecondStepLoading = true;
    });
    builder.addCase(postCreateOpportunitySecondStep.fulfilled, (state, action) => {
      state.createOpportunitySecondStepLoading = false;
      state.singleOpportunity = action.payload;
    });
    builder.addCase(postCreateOpportunitySecondStep.rejected, (state) => {
      state.createOpportunitySecondStepLoading = false;
    });

    //  update opportunity (first step)
    builder.addCase(postUpdateOpportunityFirstStep.pending, (state) => {
      state.updateOpportunityFirstStepLoading = true;
    });
    builder.addCase(postUpdateOpportunityFirstStep.fulfilled, (state, action) => {
      state.updateOpportunityFirstStepLoading = false;
      state.singleOpportunity = action.payload;
    });
    builder.addCase(postUpdateOpportunityFirstStep.rejected, (state) => {
      state.updateOpportunityFirstStepLoading = false;
    });


    //  update opportunity (second step)
    builder.addCase(postUpdateOpportunitySecondStep.pending, (state) => {
      state.updateOpportunitySecondStepLoading = true;
    });
    builder.addCase(postUpdateOpportunitySecondStep.fulfilled, (state, action) => {
      state.updateOpportunitySecondStepLoading = false;
      state.singleOpportunity = action.payload;
    });
    builder.addCase(postUpdateOpportunitySecondStep.rejected, (state) => {
      state.updateOpportunitySecondStepLoading = false;
    });

    //  cancel opportunity
    builder.addCase(postCancelOpportunity.pending, (state) => {
      state.cancelOpportunityLoading = true;
    });
    builder.addCase(postCancelOpportunity.fulfilled, (state, action) => {
      state.cancelOpportunityLoading = false;
      state.singleOpportunity = action.payload;
    });
    builder.addCase(postCancelOpportunity.rejected, (state) => {
      state.cancelOpportunityLoading = false;
    });


    //  view single opportunity
    builder.addCase(getSingleOpportunity.pending, (state) => {
      state.singleOpportunityLoading = true;
    });
    builder.addCase(getSingleOpportunity.fulfilled, (state, action) => {
      state.singleOpportunityLoading = false;
      state.singleOpportunity = action.payload;
    });
    builder.addCase(getSingleOpportunity.rejected, (state) => {
      state.singleOpportunityLoading = false;
    });

    // get bank list
    builder.addCase(getBorrowerBankList.pending, (state) => {
      state.borrowerBankListLoading = true;
    });
    builder.addCase(getBorrowerBankList.fulfilled, (state, action) => {
      state.borrowerBankListLoading = false;
      state.borrowerBankList = action.payload;
    });
    builder.addCase(getBorrowerBankList.rejected, (state) => {
      state.borrowerBankListLoading = false;
    });

    // get opportunities csv
    builder.addCase(getOpportunitiesCSV.pending, (state) => {
      state.opportunitiesListCSVLoading = true;
    });
    builder.addCase(getOpportunitiesCSV.fulfilled, (state, action) => {
      state.opportunitiesListCSVLoading = false;
      state.opportunitiesListCSV = action.payload;
    });
    builder.addCase(getOpportunitiesCSV.rejected, (state) => {
      state.opportunitiesListCSVLoading = false;
    });

    // get opportunities dropdown data
    builder.addCase(getOpportunityDropData.pending, (state) => {
      state.opportunityDropDataLoading = true;
    });
    builder.addCase(getOpportunityDropData.fulfilled, (state, action) => {
      state.opportunityDropDataLoading = false;
      state.opportunityDropData = action.payload;
    });
    builder.addCase(getOpportunityDropData.rejected, (state) => {
      state.opportunityDropDataLoading = false;
    });
  },
});

export default opportunitiesSlice.reducer;
