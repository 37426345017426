import React, {useEffect, useState} from "react";
import { Layout, Button, Row, Col, Dropdown, Menu } from "antd";

const Index = () => {
  return (
    <footer>
      <p className="m-0">Dnaneer © Copyright 2023, All Rights Reserved</p>
    </footer>
  )
}

export default Index;