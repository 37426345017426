import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../services/instance";
import { message } from "antd";

const initialState = {
    allTransactionsLoading: false,
    allTransactions: [],
    singleTransactionsLoading: false,
    singleTransactions: null,
    exportTransactionsCsvLoading: false,
    walletData: null
};

//^ ========= get all Transactions ============//
export const getAllTransactions = createAsyncThunk("Transactions/getAllTransactions", async (params) => {
        try {
            const response = await axiosInstance.get("wallet/transactions",{params});            
            return response?.data?.data || [];
        } catch (error) {
            console.error(error);
        }
    }
);


/*-----------------------Slice----------------*/

const TransactionsSlice = createSlice({
    name: "Transactions",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // get Transactions
        builder
            .addCase(getAllTransactions.pending, (state) => {
                state.allTransactionsLoading = true;
            })
            .addCase(getAllTransactions.fulfilled, (state, action) => {
                state.allTransactionsLoading = false;
                state.allTransactions = action.payload;
                state.walletData = action.payload?.data[0]?.wallet;
            })
            .addCase(getAllTransactions.rejected, (state, action) => {
                state.allTransactionsLoading = false;
                if (action.payload) {
                    message.error(action.payload.message);
                }
            });
    },
});

export default TransactionsSlice.reducer;
