import axios from "axios";
import axiosInstance from "./instance";

export const loginUser = (data) => {
  return axiosInstance.post("admin/login", data);
};

export const opportunityDropDown = () => {
  return axiosInstance.get("admin/opportunity_dropdown_data");
};

export const getOpportunityList = () => {
  return axiosInstance.get("admin/oppurtunity_list");
};

export const createOpportunity = (data) => {
  return axiosInstance.post("admin/create_opportunity", data);
};

export const updateOpportunity = (data, id) => {
  return axiosInstance.post(`admin/update_opportunity/${id}`, data);
};

export const getCRInfo = (data) => {
  return axiosInstance.post("search-registration-number", data);
};

export const getInvestorsList = (page) => {
  return axiosInstance.get(`admin/get_investors?page=${page}`);
};

export const getRequestedList = (params) => {
  return axiosInstance.get("admin/vip_requests", { params });
};
export const getSingleVipRequest = (id) => {
  return axiosInstance.get(`admin/vip_requests/${id}`);
};

export const updateVipRequest = (data) => {
  return axiosInstance.post("admin/update_vip", data);
};



export const getKYCRequestedList = () => {
  return axiosInstance.get("admin/kyc_users");
};

export const getInvestor = (id) => {
  return axiosInstance.get(`admin/get_single_investor/${id}`);
};

export const getTransactionsList = (id) => {
  return axiosInstance.get(`admin/get_transactions/${id}`);
};

export const getWithdrawalRequestListing = (request) => {
  return axiosInstance.get(`admin/get_transfer_request?status=${request}`);
};

export const getAdvisorsList = () => {
  return axiosInstance.get("admin/get_advisor_list");
};
export const deleteAdvisor = (id) => {
  return axiosInstance.delete(`admin/delete_advisor/${id}`);
};

export const getAdvisor = (id) => {
  return axiosInstance.get(`admin/get_single_advisor/${id}`);
};

export const getSingleOpportunity = (id) => {
  return axiosInstance.get(`admin/get_single_opportunity/${id}`);
};

export const getInstallmentStatus = () => {
  return axiosInstance.get(`admin/installmentStatus`);
};

export const getBankList = (id) => {
  return axiosInstance.get("bank_list");
};

export const updateInstitutionalInvestor = (data) => {
  return axiosInstance.post("admin/update_institutional_investor", data);
};

export const updateIndividualInvestor = (data) => {
  return axiosInstance.post("admin/update_individual_investor", data);
};
export const updateBorrower = (data) => {
  return axiosInstance.post("admin/update-profile-data", data);
};
export const installment = (data) => {
  return axiosInstance.post("admin/create-loan", data);
};

export const createVipRequest = (data) => {
  return axiosInstance.post("admin/accept_vip", data);
};

export const createAdminUser = (data) => {
  return axiosInstance.post("admin/create_sub_admin", data);
};
export const getBalance = (number) => {
  return axios.get(`http://8.213.25.134/api/get-anb-balance?accountNumber=${number}`);
};
export const getStatement = (number, start, end) => {
  return axios.get(
    `http://8.213.25.134/api/get-anb-statement?accountNumber=${number}&fromDate=${start}&toDate=${end}&max=5&offset=Offset&type=JSON`
  );
  // &fromDate=2010-05-28&toDate=2022-02-13&max=5&offset=Offset&type=JSON
};
export const getEODStatement = (date) => {
  return axios.get(`http://8.213.25.134/api/get-anb-statement?order=DESC&date=${date}&take=5&page=1`);
  // &fromDate=2010-05-28&toDate=2022-02-13&max=5&offset=Offset&type=JSON
};
export const rejectOpportunity = (data) => {
  return axiosInstance.post("admin/opportunity_approve", data);
};

export const getAdminUser = () => {
  return axiosInstance.get("admin/getadmins");
};

export const createKYCRequest = (data) => {
  return axiosInstance.post("admin/accept_invester_kyc", data);
};
export const accpetFundRequest = (data) => {
  return axiosInstance.post("admin/update_transfer_request", data);
};
export const getSignleKYCDetail = (id) => {
  return axiosInstance.get(`admin/get_single_investor/${id}`);
};
// export const createAdvisor = (data) => {
//     return axiosInstance.post("api/create_advisor", data);
// };
export const getBorrowerList = (page) => {
  return axiosInstance.get(`admin/get_borrowers?page=${page}`);
};
export const getSingleBorrower = (id) => {
  return axiosInstance.get(`admin/get_single_borrower/${id}`);
};
export const getRequestedBorrowerList = (page, email) => {
  return axiosInstance.get(`admin/loan_requests?page=${page}`);
};
/* ------------------------ loans requests start --------------- */
export const getLoanList = (page, filter) => {
  return axiosInstance.get(`admin/get-loans?per_page=10&page_no=${page}&status=${filter}`);
};

export const getSingleLoan = (loan_id) => {
  return axiosInstance.get(`admin/show-loan/${loan_id}`);
};

export const updateLoanStatus = (body) => {
  return axiosInstance.post("admin/update-loan", body);
};

export const updateOpportunityInstallment = (body) => {
  return axiosInstance.post(`admin/update_loan_installments`, body);
};

/* ------------------------ loans requests end --------------- */

export const getSingleRequestedBorrower = (id) => {
  return axiosInstance.get(`admin/borrower_requests_by_id/${id}`);
};
export const acceptRequestedBorrower = (body) => {
  return axiosInstance.post("admin/update_request", body);
};
export const getTranferRequestDetail = (id) => {
  return axiosInstance.get(`admin/transfer_request_by_id/${id}`);
};
export const getPartialNotifications = () => {
  return axiosInstance.get(`admin/get_latest_notification_admin`);
};
export const exportInvestorsList = (body) => {
  return axiosInstance.post(`admin/export-investors-csv`, body);
};
export const exportAdvisorsList = (body) => {
  return axiosInstance.post(`admin/export-advisors-csv`, body);
};
export const exportTransactionsList = (params) => {
  return axiosInstance.get(`wallet/export-csv-transactions`, {params});
};
export const exportBorrowersRequestList = (body) => {
  return axiosInstance.post(`admin/export-borrower-requests-csv`, body);
};
export const exportBorrowersList = (body) => {
  return axiosInstance.post(`admin/export-borrowers-csv`, body);
};
