import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../services/instance";
import { message } from "antd";

const initialState = {
  updateLoanReqStatusLoading: false,
  singleLoanReqLoading: false,
  singleLoanReq: null,
};

//^ ========= get single loan  ============
export const getSingleLoanReq = createAsyncThunk("loansReq/getSingleLoanReq", async ({ id }, { rejectWithValue }) => {
  try {
    return await axiosInstance
      .get(`admin/loan_requests/${id}`)
      .then((response) => {
        console.log(response?.data?.data, "000000000000000loan req ================");
        return response?.data?.data;
      })
      .catch((error) => {
        console.log(error);
        // return rejectWithValue({
        //   message: error.message,
        //   error: error.response.data,
        // });
      });
  } catch (e) {
    throw e;
  }
});
//^ ========= update loan req status  ============
export const postUpdateLoans = createAsyncThunk(
  "loansReq/postUpdateLoans",
  async ({ status, id }, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .post(`admin/loan_requests/${id}`, {
          status: status,
        })
        .then((response) => {
          message.success(response?.data?.message);
          console.log(response, "update");
          // update single loan
          dispatch(getSingleLoanReq({ id }));
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
        });
    } catch (e) {
      throw e;
    }
  }
);

/*-----------------------Slice----------------*/

const loanReqSlice = createSlice({
  name: "loansReqSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get single laonReq
    builder.addCase(getSingleLoanReq.pending, (state) => {
      state.singleLoanReqLoading = true;
    });
    builder.addCase(getSingleLoanReq.fulfilled, (state, action) => {
      state.singleLoanReqLoading = false;
      state.singleLoanReq = action.payload;
    });
    builder.addCase(getSingleLoanReq.rejected, (state) => {
      state.singleLoanReqLoading = false;
    });

    // update loan status
    builder.addCase(postUpdateLoans.pending, (state) => {
      state.updateLoanReqStatusLoading = true;
    });
    builder.addCase(postUpdateLoans.fulfilled, (state, action) => {
      state.updateLoanReqStatusLoading = false;
    });
    builder.addCase(postUpdateLoans.rejected, (state) => {
      state.updateLoanReqStatusLoading = false;
    });
  },
});

export default loanReqSlice.reducer;
