import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../services/instance";
import { message } from "antd";

const initialState = {
  // all loans
  allLoansLoading: false,
  allLoans: null,
  //single loan
  singleLoanLoading: false,
  singleLoan: null,
  // update loan status
  updateLoanStatusLoading: false,
  // create loan 
  createLoanLoading: false,
  // update loan installments
  updateLoanInstallmentLoading: false,
  // export loans
  loansCSV: null,
  loanCSVLoading: false,

  // loans transactions
  loansTransactionsLoading: false,
  loansTransactions: null,
};

//^ ========= get all loans ============//
export const getAllLoans = createAsyncThunk(
  "loans/getAllLoans",
  async (params, { rejectWithValue }) => {
    try {
      return await axiosInstance
        .get(`/admin/get-loans`, { params })
        .then((response) => {
          return response?.data?.data;
        })
        .catch((error) => {
          console.log(error);
          // return rejectWithValue({
          //   message: error.message,
          //   error: error.response.data,
          // });
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= get single loan ============ //
export const getSingleLoan = createAsyncThunk("loans/getSingleLoan", async ({ loan_id }, { rejectWithValue }) => {
  try {
    return await axiosInstance
      .get(`/admin/show-loan/${loan_id}`)
      .then((response) => {
        console.log(response, "ppppppppppppppppp");
        return response?.data?.data;
      })
      .catch((error) => {
        console.log(error);
        // return rejectWithValue({
        //   message: error.message,
        //   error: error.response.data,
        // });
      });
  } catch (e) {
    throw e;
  }
});

//^ ========= update loan status ============
export const postUpdateLoanStatus = createAsyncThunk(
  "loans/postUpdateLoanStatus",
  async ({ data, currentStatus }, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .post("/admin/update-loan", data)
        .then((response) => {
          message.success(response?.data?.message || "updated successfully");
          console.log(response, "post");
          // dispatch get all loans
          dispatch(getAllLoans({ status: currentStatus, per_page: 10, page_no: 1 }));
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message || "failed");
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= create loan status ============
export const postCreateLoan = createAsyncThunk(
  "loans/postCreateLoanInstallments",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .post(`/admin/create-loan`, data)
        .then((response) => {
          message.success(response?.data?.message);
          console.log(response?.data?.data, "update");
          return response?.data?.data;
        })
        .catch((error) => {
          message.error(error?.response?.data?.message);
          return rejectWithValue({
            message: error.message,
            error: error.response.data,
          });
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= update loan installments ============
export const postUpdateLoanInstallments = createAsyncThunk(
  "loans/postUpdateLoanInstallments",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .post(`/admin/update_loan_installments`, data)
        .then((response) => {
          message.success(response?.data?.message);
          console.log(response?.data?.data, "update");
          return response?.data?.data
        })
        .catch((error) => {
          console.log(error);
          message.error(error?.response?.data?.message);
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= get loans csv  ============ //
export const getLoansCSV = createAsyncThunk("loans/getLoansCSV", async (data, { rejectWithValue, dispatch }) => {
  try {
    return await axiosInstance
      .get(`/admin/export-loans`)
      .then((response) => {
        console.log(response?.data?.data, "getLoansCSV");
        return response?.data?.data?.fileUrl;
      })
      .catch((error) => {
        return rejectWithValue({
          error: error.message,
        });
      });
  } catch (e) {
    throw e;
  }
});

//^ ========= get loans transactions  ============ //

export const getLoansTransactions = createAsyncThunk(
  "loans/getLoansTransactions",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      return await axiosInstance
        .get(`admin/get-loan-transactions/${id}`, { params })
        .then((response) => {
          return response?.data?.data;
        })
        .catch((error) => {
          console.log(error);
          // return rejectWithValue({
          //   message: error.message,
          //   error: error.response.data,
          // });
        });
    } catch (e) {
      throw e;
    }
  }
);
/*-----------------------Slice----------------*/

const loansSlice = createSlice({
  name: "loansSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all loans
    builder.addCase(getAllLoans.pending, (state) => {
      state.allLoansLoading = true;
    });
    builder.addCase(getAllLoans.fulfilled, (state, action) => {
      state.allLoansLoading = false;
      state.allLoans = action.payload;
    });
    builder.addCase(getAllLoans.rejected, (state) => {
      state.allLoansLoading = false;
    });

    //  get single loan
    builder.addCase(getSingleLoan.pending, (state) => {
      state.singleLoanLoading = true;
    });
    builder.addCase(getSingleLoan.fulfilled, (state, action) => {
      state.singleLoanLoading = false;
      state.singleLoan = action.payload;
    });
    builder.addCase(getSingleLoan.rejected, (state) => {
      state.singleLoanLoading = false;
    });

    // update loan status
    builder.addCase(postUpdateLoanStatus.pending, (state) => {
      state.updateLoanStatusLoading = true;
    });
    builder.addCase(postUpdateLoanStatus.fulfilled, (state, action) => {
      state.updateLoanStatusLoading = false;
    });
    builder.addCase(postUpdateLoanStatus.rejected, (state) => {
      state.updateLoanStatusLoading = false;
    });

    // create loan
    builder.addCase(postCreateLoan.pending, (state) => {
      state.createLoanLoading = true;
    });
    builder.addCase(postCreateLoan.fulfilled, (state, action) => {
      state.createLoanLoading = false;
      state.singleLoan = action.payload;
    });
    builder.addCase(postCreateLoan.rejected, (state) => {
      state.createLoanLoading = false;
    });

    // update loan installments
    builder.addCase(postUpdateLoanInstallments.pending, (state) => {
      state.updateLoanInstallmentLoading = true;
    });
    builder.addCase(postUpdateLoanInstallments.fulfilled, (state, action) => {
      state.updateLoanInstallmentLoading = false;
      state.singleLoan = action.payload
    });
    builder.addCase(postUpdateLoanInstallments.rejected, (state) => {
      state.updateLoanInstallmentLoading = false;
    });

    // get loan csv
    builder.addCase(getLoansCSV.pending, (state) => {
      state.loanCSVLoading = true;
    });
    builder.addCase(getLoansCSV.fulfilled, (state, action) => {
      state.loanCSVLoading = false;
      state.loansCSV = action.payload;
    });
    builder.addCase(getLoansCSV.rejected, (state) => {
      state.loanCSVLoading = false;
    });

    // loans transactions
    builder.addCase(getLoansTransactions.pending, (state) => {
      state.loansTransactionsLoading = true;
    });
    builder.addCase(getLoansTransactions.fulfilled, (state, action) => {
      state.loansTransactionsLoading = false;
      state.loansTransactions = action.payload;
    });
    builder.addCase(getLoansTransactions.rejected, (state) => {
      state.loansTransactionsLoading = false;
    });

  },
});

export default loansSlice.reducer;
