import React, { useEffect, useState } from "react";

import Header from "../header";
import Footer from "../footer";
import Sidebar from "../sidebar";

const Layout = (props) => {
  //console.log(props)
  return (
    <div className="gen-page">
      <Header />
      <div className="main-section">
        <Sidebar sideKey={props.sideKey} />
        <main>
          <div className="container h-full">{props.children}</div>
        </main>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
