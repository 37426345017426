import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../services/instance";
import { message } from "antd";

const initialState = {
    allAdvisorsLoading: false,
    allAdvisors: [],

    singleAdvisorLoading: false,
    singleAdvisor: null,

    createAdvisorLoading: false,
    updateAdvisorLoading: false,
    deleteAdvisorLoading: false,
    exportAdvisorsCSvLoading: false,
};

//^ ========= get all advisors ============//
export const getAllAdvisors = createAsyncThunk("financialAdvisor/getAllAdvisors", async (data, { rejectWithValue }) => {
    try {
        return await axiosInstance
            .get("admin/get_advisor_list")
            .then((response) => {
                return response?.data?.data || [];
            })
            .catch((error) => {
                console.log(error);
                // return rejectWithValue({
                //   message: error.message,
                //   error: error.response.data,
                // });
            });
    } catch (e) {
        throw e;
    }
});

//^ ========= create advisor ============
export const postCreateAdvisor = createAsyncThunk("financialAdvisor/postCreateAdvisor", async (data, { rejectWithValue, dispatch }) => {
    try {
        return await axiosInstance
            .post(`admin/create_advisor`, data)
            .then((response) => {
                message.success(response?.data?.message);
                console.log(response, "post");
                dispatch(getAllAdvisors());
            })
            .catch((error) => {
                message.error(error?.response?.data?.message);
                return rejectWithValue({
                    message: error.message,
                    error: error.response.data,
                });
            });
    } catch (e) {
        throw e;
    }
});

//^ ========= update advisor ============
export const postUpdateAdvisor = createAsyncThunk(
    "financialAdvisor/postUpdateAdvisor",
    async ({ id, data }, { rejectWithValue, dispatch }) => {
        try {
            return await axiosInstance
                .post(`/admin/update_advisor/${id}`, data)
                .then((response) => {
                    message.success(response?.data?.message);
                    console.log(response, "update");
                    dispatch(getAllAdvisors());
                })
                .catch((error) => {
                    console.log(error);
                    message.error(error?.response?.data?.message);
                });
        } catch (e) {
            throw e;
        }
    }
);

//^ ========= delete advisor ============
export const deleteAdvisor = createAsyncThunk("financialAdvisor/deleteAdvisor", async (id, { rejectWithValue, dispatch }) => {
    try {
        return await axiosInstance
            .delete(`admin/delete_advisor/${id}`)
            .then((response) => {
                message.success(response?.data?.message);
                console.log(response, "delete");
                dispatch(getAllAdvisors());
            })
            .catch((error) => {
                console.log(error);
                message.error(error?.response?.data?.message || "request failed");
            });
    } catch (e) {
        throw e;
    }
});

export const postAdvisorsCSV = createAsyncThunk("financialAdvisor/getAdvisorsCSV", async (body) => {
    try {
        return await axiosInstance
            .post(`admin/export-advisors-csv`, body)
            .then((response) => {
                const link = document.createElement("a");
                link.href = response?.data?.data?.fileUrl;
                link.download = "investors.pdf";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                return response?.data?.data?.fileUrl;
            })
            .catch((error) => {
                console.log(error);
            });
    } catch (e) {
        throw e;
    }
});
/*-----------------------Slice----------------*/

const FinancialAdvisorsSlice = createSlice({
    name: "FinancialAdvisors",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // get advisors
        builder.addCase(getAllAdvisors.pending, (state) => {
            state.allAdvisorsLoading = true;
        });
        builder.addCase(getAllAdvisors.fulfilled, (state, action) => {
            state.allAdvisorsLoading = false;
            state.allAdvisors = action.payload;
        });
        builder.addCase(getAllAdvisors.rejected, (state) => {
            state.allAdvisorsLoading = false;
        });

        // post advisor
        builder.addCase(postCreateAdvisor.pending, (state) => {
            state.createAdvisorLoading = true;
        });
        builder.addCase(postCreateAdvisor.fulfilled, (state, action) => {
            state.createAdvisorLoading = false;
        });
        builder.addCase(postCreateAdvisor.rejected, (state) => {
            state.createAdvisorLoading = false;
        });

        // update advisor
        builder.addCase(postUpdateAdvisor.pending, (state) => {
            state.updateAdvisorLoading = true;
        });
        builder.addCase(postUpdateAdvisor.fulfilled, (state, action) => {
            state.updateAdvisorLoading = false;
        });
        builder.addCase(postUpdateAdvisor.rejected, (state) => {
            state.updateAdvisorLoading = false;
        });

        // delete advisor
        builder.addCase(deleteAdvisor.pending, (state) => {
            state.deleteAdvisorLoading = true;
        });
        builder.addCase(deleteAdvisor.fulfilled, (state, action) => {
            state.deleteAdvisorLoading = false;
        });
        builder.addCase(deleteAdvisor.rejected, (state) => {
            state.deleteAdvisorLoading = false;
        });

        // export advisor
        builder.addCase(postAdvisorsCSV.pending, (state) => {
            state.exportAdvisorsCSvLoading = true;
        });
        builder.addCase(postAdvisorsCSV.fulfilled, (state, action) => {
            state.exportAdvisorsCSvLoading = false;
        });
        builder.addCase(postAdvisorsCSV.rejected, (state) => {
            state.exportAdvisorsCSvLoading = false;
        });
    },
});

export default FinancialAdvisorsSlice.reducer;