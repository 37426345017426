import React, { Component } from "react";
import "./styles/base.scss";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import MyRoutes from "./Routes";
import { store } from "./store";
import { ConfigProvider } from "antd";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <ConfigProvider
            theme={{
              token: {
                fontFamily: "Inter",
                colorPrimary: "#4B5563",
              },
            }}
          >
            <MyRoutes />
          </ConfigProvider>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
