import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../services/instance";
import { message } from "antd";

const initialState = {
  allWithdrawReq: null,
  allWithdrawReqLoading: false,
  singleWithdrawalReqLoading: false,
  singleWithdrawalReq: null,
  updateWithdrawalReqLoading: false,
  // csv export
  allWithdrawReqCSV: null,
  allWithdrawReqCSVLoading: false,
};

//^ ========= get all withdraw req ============
export const getAllWithdrawReq = createAsyncThunk(
  "withdrawalReq/getAllWithdrawReq",
  async ({ status, page, perPage, email }, { rejectWithValue, dispatch }) => {
    try {
      let url = `/admin/get_transfer_request?status=${status}&perPage=${perPage}&page=${page}`;
      if (email) {
        url += `&email=${email}`;
      }

      return await axiosInstance
        .get(url)
        .then((response) => {
          return response?.data?.data;
        })
        .catch((error) => {
          console.log(error);
          // return rejectWithValue({
          //   message: error.message,
          //   error: error.response.data,
          // });
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= getSingleWithdrawReq ============
export const getSingleWithdrawReq = createAsyncThunk(
  "withdrawalReq/getSingleWithdrawReq",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .get(`/admin/transfer_request_by_id/${id}`)
        .then((response) => {
          console.log(response?.data?.data, "getSingleWithdrawReq ================");
          return response?.data?.data;
        })
        .catch((error) => {
          console.log(error);
          // return rejectWithValue({
          //   message: error.message,
          //   error: error.response.data,
          // });
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= update withdrawal req status  ============
export const postUpdateWithdrawalReq = createAsyncThunk(
  "withdrawalReq/postUpdateWithdrawalReq",
  async ({ status, id, reject_note }, { rejectWithValue, dispatch }) => {
    try {
      return await axiosInstance
        .post(`/admin/update_transfer_request`, {
          status: status,
          id: id,
          reject_note: reject_note,
        })
        .then((response) => {
          message.success(response?.data?.message);
          console.log(response, "update");
          // update single loan
          dispatch(getSingleWithdrawReq({ id }));
        })
        .catch((error) => {
          console.log(error?.response, "--------");
          message.error(error?.response?.data?.message || error?.response?.data?.errors?.reject_note[0]);
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= get withdrawal req csv  ============ //
export const getWithdrawalReqCSV = createAsyncThunk(
  "withdrawalReq/getWithdrawalReqCSV",
  async (data, { rejectWithValue }) => {
    try {
      return await axiosInstance
        .get(`/admin/get_transfer_request/export_csv`)
        .then((response) => {
          console.log(response?.data?.data?.fileUrl, "getWithdrawalReqCSV");
          return response?.data?.data?.fileUrl;
        })
        .catch((error) => {
          console.log(error);
          return rejectWithValue({
            error: error.message,
          });
        });
    } catch (e) {
      throw e;
    }
  }
);
/*-----------------------Slice----------------*/

const withdrawalReqSlice = createSlice({
  name: "withdrawalReqSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all withdraw req
    builder.addCase(getAllWithdrawReq.pending, (state) => {
      state.allWithdrawReqLoading = true;
    });
    builder.addCase(getAllWithdrawReq.fulfilled, (state, action) => {
      state.allWithdrawReqLoading = false;
      state.allWithdrawReq = action.payload;
    });
    builder.addCase(getAllWithdrawReq.rejected, (state) => {
      state.allWithdrawReqLoading = false;
    });

    // get singleWithdrawal
    builder.addCase(getSingleWithdrawReq.pending, (state) => {
      state.singleWithdrawalReqLoading = true;
    });
    builder.addCase(getSingleWithdrawReq.fulfilled, (state, action) => {
      state.singleWithdrawalReqLoading = false;
      state.singleWithdrawalReq = action.payload;
    });
    builder.addCase(getSingleWithdrawReq.rejected, (state) => {
      state.singleWithdrawalReqLoading = false;
    });

    // update withdrawal
    builder.addCase(postUpdateWithdrawalReq.pending, (state) => {
      state.updateWithdrawalReqLoading = true;
    });
    builder.addCase(postUpdateWithdrawalReq.fulfilled, (state, action) => {
      state.updateWithdrawalReqLoading = false;
    });
    builder.addCase(postUpdateWithdrawalReq.rejected, (state) => {
      state.updateWithdrawalReqLoading = false;
    });

    // export csv (withdrawal req)
    builder.addCase(getWithdrawalReqCSV.pending, (state) => {
      state.allWithdrawReqCSVLoading = true;
    });
    builder.addCase(getWithdrawalReqCSV.fulfilled, (state, action) => {
      state.allWithdrawReqCSVLoading = false;
      state.allWithdrawReqCSV = action.payload;
    });
    builder.addCase(getWithdrawalReqCSV.rejected, (state) => {
      state.allWithdrawReqCSVLoading = false;
    });
  },
});

export default withdrawalReqSlice.reducer;
